// extracted by mini-css-extract-plugin
export var heading = "UiComponents-module--heading--2NNPa";
export var uicontainer = "UiComponents-module--uicontainer--1mlb5";
export var uicontainerinner = "UiComponents-module--uicontainerinner--1Sm9s";
export var container = "UiComponents-module--container--1XN19";
export var inner = "UiComponents-module--inner--2Mccv";
export var popover = "UiComponents-module--popover--1l5IK";
export var popoverButton = "UiComponents-module--popoverButton--Hb_6f";
export var popoverPanel = "UiComponents-module--popoverPanel--lk3le";
export var documentation = "UiComponents-module--documentation--2Bm5B";
export var documentationheading = "UiComponents-module--documentationheading--1onHy";
export var documentationtext = "UiComponents-module--documentationtext--2iHqZ";
export var radiocontainer = "UiComponents-module--radiocontainer--3WDy3";
export var radioheading = "UiComponents-module--radioheading--22usM";
export var active = "UiComponents-module--active--394PI";
export var inactive = "UiComponents-module--inactive--2X6C3";
export var radiooption = "UiComponents-module--radiooption--1D0zZ";
export var radiodescription = "UiComponents-module--radiodescription--yjhR9";
export var tick = "UiComponents-module--tick--3iyr2";
export var textcontainer = "UiComponents-module--textcontainer--3UvxW";
export var text = "UiComponents-module--text--39pHP";