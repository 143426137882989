// extracted by mini-css-extract-plugin
export var tabselect = "TabMotion-module--tabselect--2jj70";
export var textselectone = "TabMotion-module--textselectone--1Ee8z";
export var onebold = "TabMotion-module--onebold--2XYJZ";
export var textselecttwo = "TabMotion-module--textselecttwo--1TIRl";
export var twobold = "TabMotion-module--twobold--3Zxot";
export var border = "TabMotion-module--border--2XwLX";
export var tabmove = "TabMotion-module--tabmove--3G-M2";
export var content = "TabMotion-module--content--2BU_o";
export var purple = "TabMotion-module--purple--6lC_M";
export var lefttrans = "TabMotion-module--lefttrans--3Thxj";
export var colors = "TabMotion-module--colors--31e_B";
export var righttrans = "TabMotion-module--righttrans--2Z8rU";
export var pitem = "TabMotion-module--pitem--29i8-";
export var citem = "TabMotion-module--citem--1vDhI";
export var outer = "TabMotion-module--outer--13yl4";