// extracted by mini-css-extract-plugin
export var uikit = "UiKit-module--uikit--lkKaH";
export var headline = "UiKit-module--headline--3KEKf";
export var colorpicker = "UiKit-module--colorpicker--1zZWz";
export var color = "UiKit-module--color--1l7kt";
export var lightcolor = "UiKit-module--lightcolor--18yzS";
export var components = "UiKit-module--components--26Y1V";
export var inputcomponent = "UiKit-module--inputcomponent--2065j";
export var redinput = "UiKit-module--redinput--1LUqj";
export var purpleinput = "UiKit-module--purpleinput--2Tj7D";
export var greeninput = "UiKit-module--greeninput--2JHlF";
export var blueinput = "UiKit-module--blueinput--1B0wQ";
export var whiteinput = "UiKit-module--whiteinput--2ZYVw";
export var blackinput = "UiKit-module--blackinput--3IoEX";
export var buttoncomponent = "UiKit-module--buttoncomponent--3_mwW";
export var dark = "UiKit-module--dark--3KPXA";
export var light = "UiKit-module--light--3hDIe";
export var radioitem = "UiKit-module--radioitem--3dCMb";
export var redswitch = "UiKit-module--redswitch--NA_BY";
export var redslider = "UiKit-module--redslider--CZ3DK";
export var purpleswitch = "UiKit-module--purpleswitch--2Cpwu";
export var purpleslider = "UiKit-module--purpleslider--2pDQA";
export var blueswitch = "UiKit-module--blueswitch--3o5U0";
export var blueslider = "UiKit-module--blueslider--D9Bhp";
export var greenswitch = "UiKit-module--greenswitch--1eq1d";
export var greenslider = "UiKit-module--greenslider--2L2kY";
export var whiteswitch = "UiKit-module--whiteswitch--34Mhf";
export var whiteslider = "UiKit-module--whiteslider--AKJn3";
export var blackswitch = "UiKit-module--blackswitch--1hiO0";
export var blackslider = "UiKit-module--blackslider--1wFfl";
export var icons = "UiKit-module--icons--1cf4s";
export var icon = "UiKit-module--icon--14Vhf";
export var lighticon = "UiKit-module--lighticon--24etX";
export var desktopcomponents = "UiKit-module--desktopcomponents--21i0b";
export var titlep = "UiKit-module--titlep--3fwHm";